import { getAuthToken } from "./auth";

const base = process.env.REACT_APP_API_BASE;
const TIMEOUT = 5 * 1000; // ms

interface ReqOptions {
	auth?: boolean;
	body?: any;
}

const DEFAULT_OPTIONS: ReqOptions = {
	auth: true,
	body: undefined,
}

function timeout<T>(ms: number, promise: Promise<T>): Promise<T> {
	return new Promise(function (resolve, reject) {
		const t = setTimeout(function () {
			reject(new Error("timeout"))
		}, ms);
		
		promise.then(res => {
			clearTimeout(t);
			resolve(res);
		}, reject);
	});
}

async function req(method: string, endpoint: string, options: ReqOptions = DEFAULT_OPTIONS): Promise<Response> {
	const {
		auth,
		body,
	} = options;
	
	const headers: any = {
		"Upsince-Version-Date": "2020-01-05",
	};
	
	if (auth !== false) {
		const authToken = getAuthToken();
		headers["authorization"] = `Bearer ${authToken}`;
	}
	
	let strBody = undefined;
	
	if (body !== undefined) {
		if (typeof body === "object") {
			headers["content-type"] = "application/json";
			strBody = JSON.stringify(body);
		}
	}
	
	const url = `${base}/${endpoint}`;
	
	const res = await timeout(TIMEOUT, fetch(url, {
		method: method.toUpperCase(),
		headers,
		body: strBody,
	}));
	
	return res;
}

export {
	req,
}