const AUTH_TOKEN_KEY = "auth-token";

let inMem: string | undefined;
const handlers: (() => void)[] = [];

export function setAuthToken(token: string, rememberMe: boolean) {
	inMem = token;
	
	if (rememberMe) {
		localStorage.setItem(AUTH_TOKEN_KEY, token);
	}
	else {
		sessionStorage.setItem(AUTH_TOKEN_KEY, token);
	}
	
	for (const handler of handlers) {
		handler();
	}
}

export function getAuthToken(): string {
	if (inMem !== undefined) {
		return inMem;
	}
	
	const fromLocal = localStorage.getItem(AUTH_TOKEN_KEY);
	
	if (fromLocal !== null) {
		return fromLocal;
	}
	
	const fromSession = sessionStorage.getItem(AUTH_TOKEN_KEY);
	
	if (fromSession !== null) {
		return fromSession;
	}
	
	return "";
}

export function hasAuthToken(): boolean {
	return getAuthToken() !== "";
}

export function clearAuthToken() {
	inMem = undefined;
	localStorage.removeItem(AUTH_TOKEN_KEY);
	sessionStorage.removeItem(AUTH_TOKEN_KEY);
	
	for (const handler of handlers) {
		handler();
	}
}

export function onAuthChanged(handler: () => void): {} {
	handlers.push(handler);
	return {};
}

export function offAuthChanged(handler: {}): void {
	for (let i = 0; i < handlers.length; i++) {
		if (handlers[i] === handler) {
			handlers.splice(i, 1);
		}
	}
}