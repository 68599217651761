import { createReducer } from "redux-starter-kit";

export interface Invoice {
	id: string;
	date: string;
	message: string;
	amount?: {
		unit: string;
		value: number;
	};
	status?: string;
}

interface InvoicesState {
	loadStatus: string;	
	all: Invoice[];
}

const INITIAL_STATE: InvoicesState = {
	loadStatus: "",
	all: [],
};

const reducer = createReducer(INITIAL_STATE, {
	"get-invoices.start": (state) => ({ ...state, loadStatus: "pending" }),
	"get-invoices": (state, action) => ({ ...state, all: action.data.items, loadStatus: "success" }),
});

const selectState = (state: any): InvoicesState => state.invoices as InvoicesState;
export const selectLoadStatus = (state: any) => selectState(state).loadStatus;
export const selectAll = (state: any) => selectState(state).all;

export default reducer;