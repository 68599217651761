import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Container } from 'react-bulma-components';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import NavBar from './components/NavBar';
import { hasAuthToken } from './auth';
import Loadable from 'react-loadable';
import { StripeProvider, Elements } from 'react-stripe-elements';
import Footer from './components/Footer';

const PrivateRoute = ({ children, render, ...rest }: any) => (
	<Route {...rest} render={(props) => {
		if (hasAuthToken()) {
			if (render !== undefined) {
				return render(props);
			}
			
			return children;
		}
		
		sessionStorage.setItem("redirect-on-auth", props.location.pathname)
		
		return (
			<Redirect to="/login" />
		);
	}} />
)

const LoadableVerifyEmailPage = Loadable({
	loader: () => import("./pages/auth/verify-email"),
	loading: () => null,
});

const LoadableDevicesPage = Loadable({
	loader: () => import("./pages/devices"),
	loading: () => null,
});

const LoadableDevicePage = Loadable({
	loader: () => import("./pages/devices/show"),
	loading: () => null,
});

const LoadableAuthPage = Loadable({
	loader: () => import("./pages/auth"),
	loading: () => null,
});

const LoadableLoginPage = Loadable({
	loader: () => import("./pages/login"),
	loading: () => null,
});

const LoadableRegistrationPage = Loadable({
	loader: () => import("./pages/register"),
	loading: () => null,
});

const LoadableForgotPasswordPage = Loadable({
	loader: () => import("./pages/auth/forgot-password"),
	loading: () => null,
});

const LoadableResetPasswordPage = Loadable({
	loader: () => import("./pages/auth/reset-password"),
	loading: () => null,
});

const LoadableAccountPage = Loadable({
	loader: () => import("./pages/account"),
	loading: () => null,
});

const LoadableBillingPage = Loadable({
	loader: () => import("./pages/billing"),
	loading: () => null,
});

const LoadableNotFoundPage = Loadable({
	loader: () => import("./pages/not-found"),
	loading: () => null,
});

const LoadableSupportPage = Loadable({
	loader: () => import("./pages/support"),
	loading: () => null,
});

const App: React.FC = () => {
	return (
		<StripeProvider apiKey="pk_test_ad2Iff6KDakxX5hRvB3LNZia00QaTAMCVv">
		<Router>
				<NavBar />
				<Container style={{ padding: "1rem" }}>
					<div
						style={{
							marginTop: "1rem",
						}}
					>
						<Switch>
							<PrivateRoute exact path="/devices">
								<LoadableDevicesPage />
							</PrivateRoute>
							
							<PrivateRoute exact path="/devices/:id">
								<LoadableDevicePage />
							</PrivateRoute>
							
							<PrivateRoute exact path="/devices/:id/:tab">
								<LoadableDevicePage />
							</PrivateRoute>
							
							<Route exact path="/auth">
								<LoadableAuthPage />
							</Route>
							
							<Route exact path="/login">
								<LoadableLoginPage />
							</Route>
							
							<Route exact path="/signup">
								<LoadableRegistrationPage />
							</Route>
							
							<Route exact path="/forgot-password">
								<LoadableForgotPasswordPage />
							</Route>
							
							<Route exact path="/reset-password">
								<LoadableResetPasswordPage />
							</Route>
							
							<PrivateRoute exact path="/account">
								<LoadableAccountPage />
							</PrivateRoute>
							
							<Route exact path="/verify-email">
								<LoadableVerifyEmailPage />
							</Route>
							
							<Route exact path="/support">
								<LoadableSupportPage />
							</Route>
							
							<PrivateRoute exact path="/billing">
								<Elements>
									<LoadableBillingPage />
								</Elements>
							</PrivateRoute>
							
							<Route exact path="/">
								<Redirect to="/devices" />
							</Route>
							
							<Route>
								<LoadableNotFoundPage />
							</Route>
						</Switch>
					</div>
					
				</Container>
				<Footer />
			</Router>
		</StripeProvider>
	);
}

export default App;
