import React from "react";

interface FooterProps {
	// todo
}

const Footer: React.FC<FooterProps> = (props) => {
	return (
		<footer className="footer">
			<div className="content has-text-centered">
				Copyright 2019 Upsince, LLC.
			</div>
		</footer>
	);
};

export default Footer;