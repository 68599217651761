import { configureStore, combineReducers } from "redux-starter-kit";

import user from "./user";
import devices from "./devices";
import billing from "./billing";
import invoices from "./invoices";

const reducer = combineReducers({
	user,
	devices,
	billing,
	invoices,
});

const store = configureStore({
	reducer,
});

export default store;