import { useState, useEffect } from "react";
import { onAuthChanged, offAuthChanged, hasAuthToken } from "../auth";

export default function useHasAuthToken(): boolean {
	const [value, setValue] = useState(hasAuthToken());
	
	useEffect(() => {
		const handler = onAuthChanged(() => {
			setValue(hasAuthToken());
		});
		
		return offAuthChanged(handler);
	}, []);
	
	return value;
}