import { Device } from "../types/device";
import { LoadStatus } from "../types/load-status";

interface DevicesState {
	allLoadStatus: LoadStatus;
	all: Device[];
}

const INITIAL_STATE: DevicesState = {
	allLoadStatus: LoadStatus.None,
	all: [],
};

const reducer = (state: DevicesState = INITIAL_STATE, action: any): DevicesState => {
	if (action.type === "set-devices.start") {
		return {
			...state,
			allLoadStatus: LoadStatus.Started,
			all: [],
		};
	}
	
	if (action.type === "set-devices") {
		return {
			...state,
			all: action.data,
			allLoadStatus: LoadStatus.Done,
		};
	}
	
	if (action.type === "set-devices.error") {
		return {
			...state,
			allLoadStatus: LoadStatus.Fail,
		};
	}
	
	if (action.type === "edit-device") {
		const newAll = [...state.all];
		
		for (let i = 0; i < newAll.length; i++) {
			if (newAll[i].id === action.data.id) {
				newAll[i] = {
					...newAll[i],
					name: action.data.name,
				};
				
				break;
			}
		}
		
		return {
			...state,
			all: newAll,
		};
	}
	
	if (action.type === "remove-device") {
		const {
			id,
		} = action.data;
		
		return {
			...state,
			all: state.all.filter(d => d.id !== id),
		};
	}
	
	if (action.type === "logout") {
		return INITIAL_STATE;
	}

	return state;
};

const selectState = (state: any): DevicesState => state.devices as DevicesState;

export const selectAll = (state: any) => selectState(state).all;
export const selectAllLoadStatus = (state: any) => selectState(state).allLoadStatus;

export default reducer;