import * as React from "react";
import { Navbar, Loader, Icon } from "react-bulma-components";
import { useSelector, useDispatch } from "react-redux";
import { selectName } from "../reducers/user";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faSignOutAlt, faAddressCard } from '@fortawesome/free-solid-svg-icons'
import logo from "../res/logo.png";
import { clearAuthToken } from "../auth";
import { useEffect, useRef, createContext, useContext } from "react";
import { req } from "../api";
import { NavLink, useHistory } from "react-router-dom";
import useHasAuthToken from "../hooks/useHasAuthToken";

const Context = createContext<any>(undefined);

const wwwUrl = "/";
const getWwwUrl = process.env.REACT_APP_GET_WWW_URL;

interface NavItemProps {
	to?: string;
	neverActive?: boolean;
	onClick?(): void;
	
	dropdown?: any;
	hoverable?: any;
}

const NavItem: React.FC<NavItemProps> = (props) => {
	const {
		to = "#",
		children,
		neverActive,
		dropdown,
		hoverable,
	} = props;
	
	const Item = Navbar.Item as any;
	
	const ref = useRef<HTMLAnchorElement>(null);
	const context = useContext(Context);
	
	function onClick() {
		ref.current!.blur();
		context.onCloseMenu();
		
		if (props.onClick !== undefined) {
			props.onClick();
		}
	}
	
	return (
		<Item
			renderAs={NavLink}
			to={to}
			activeClassName={neverActive === true ? "" : "is-active"}
			onClick={onClick}
			innerRef={ref}
			dropdown={dropdown}
			hoverable={hoverable}
		>
			{children}
		</Item>
	);
};

interface UnauthedMenuProps {
	isOpen: boolean;
}

const UnauthedMenu = (props: UnauthedMenuProps) => {
	const {
		isOpen,
	} = props;

	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			dispatch({ type: "set-user.start" });
			const res = await req("get", "v1/users/me");
			dispatch({ type: "set-user", data: await res.json() });
		})()
	}, [dispatch]);

	return (
		<div className={["navbar-menu", (isOpen ? "is-active" : "")].join(" ")}>
			<NavItem to="/login">
				Login
            </NavItem>

			<NavItem to="/signup">
				Sign Up
            </NavItem>

			<Navbar.Container position="end">
				<Navbar.Item>
					<a className="button is-light" href={process.env.REACT_APP_GET_WWW_URL}>
						Back
					</a>
				</Navbar.Item>
			</Navbar.Container>
		</div>
	);
};

interface MenuProps {
	isOpen: boolean;
}

const Menu = (props: MenuProps) => {
	const {
		isOpen,
	} = props;
	
	const NavBarLink = Navbar.Link as any;
	
	const userName = useSelector(selectName);
	
	const dispatch = useDispatch();
	const history = useHistory();
	
	useEffect(() => {
		(async () => {
			dispatch({ type: "set-user.start" });
			
			try {
				const res = await req("get", "v1/users/me");
				dispatch({ type: "set-user", data: await res.json() });
			}
			catch (e) {
				dispatch({ type: "set-user.error", data: JSON.stringify(e) });
			}
		})()
	}, [dispatch]);
	
	function onClickLogout() {
		dispatch({ type: "logout" });
		
		clearAuthToken();
		history.replace("/login");
	}
	
	return (
		<div className={["navbar-menu", (isOpen ? "is-active" : "")].join(" ")}>
			<NavItem to="/devices">
				Devices
            </NavItem>
			
			<NavItem to="/support">
				Support
            </NavItem>
			
			<Navbar.Container position="end">
				<NavItem neverActive={true} dropdown={userName !== ""} hoverable={true} to="/account">
					<NavBarLink arrowless={userName === ""}>
						<Icon>
							<FontAwesomeIcon icon={faUser} />
						</Icon>
						<span
							style={{
								marginLeft: "0.1rem",
							}}
						>
							{userName === "" ? <Loader /> : userName}
						</span>
					</NavBarLink>

					{userName !== "" && (
						<Navbar.Dropdown right={true}>
							<NavItem to="/account">
								<Icon>
									<FontAwesomeIcon icon={faAddressCard} />
								</Icon>
								<span>
									My Account
								</span>
							</NavItem>
							<NavItem to="/billing">
								<Icon>
									<FontAwesomeIcon icon={faCreditCard} />
								</Icon>
								<span>
									Billing Info
								</span>
							</NavItem>

							<Navbar.Divider />

							<NavItem onClick={onClickLogout} neverActive={true}>
								<Icon>
									<FontAwesomeIcon icon={faSignOutAlt} />
								</Icon>
								<span>
									Logout
								</span>
							</NavItem>
						</Navbar.Dropdown>
					)}
				</NavItem>
			</Navbar.Container>
		</div>	
	);
};

interface NavBarProps {
	// todo
}

const NavBar = (props: NavBarProps) => {	
	const hasAuthToken = useHasAuthToken();
	
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const userName = useSelector(selectName);
	
	function onClickBurger() {
		setIsMenuOpen(!isMenuOpen);
	}
	
	const context = {
		onCloseMenu() {
			setIsMenuOpen(false);
		}
	};
	
	const Item = Navbar.Item as any;
	
	return (
		<Context.Provider value={context}>	
			<Navbar
				fixed={"top"}
				active={isMenuOpen}
				style={{
					height: "1rem",
				}}
			>
				<Navbar.Brand>
					{hasAuthToken ? (
						<NavItem to={wwwUrl} neverActive={true}>
							<img src={logo} alt="upsince" width="112" height="28" />
						</NavItem>
					) : (
						<Item
							renderAs={"a"}
							href={getWwwUrl}
							activeClassName={"is-active"}
						>
							<img src={logo} alt="upsince" width="112" height="28" />
						</Item>
					)}
					
					{userName !== "" && (
						<Navbar.Burger
							onClick={onClickBurger}
						/>
					)}
				</Navbar.Brand>
				
				{hasAuthToken && <Menu isOpen={isMenuOpen} />}
				{!hasAuthToken && <UnauthedMenu isOpen={isMenuOpen} />}
			</Navbar>
		</Context.Provider>
	);
};

export default NavBar;
