import { LoadStatus } from "../types/load-status";

interface UserState {
	loadStatus: LoadStatus;
	name: string;
	email: string;
}

const INITIAL_STATE: UserState = {
	loadStatus: LoadStatus.None,
	name: "",
	email: "",
};

const reducer = (state: UserState = INITIAL_STATE, action: any): UserState => {
	if (action.type === "set-user.start") {
		return {
			...state,
			loadStatus: LoadStatus.Started,
		};
	}
	
	if (action.type === "set-user") {
		return {
			...state,
			loadStatus: LoadStatus.Done,
			name: action.data.name,
			email: action.data.email,
		};
	}
	
	if (action.type === "set-user.error") {
		return {
			...state,
			loadStatus: LoadStatus.Fail,
		};
	}

	return state;
};

export const selectLoadStatus = (state: any) => state.user.loadStatus;
export const selectName = (state: any) => state.user.name;
export const selectEmail = (state: any) => state.user.email;

export default reducer;