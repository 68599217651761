import { createReducer } from "redux-starter-kit";

interface BillingState {
	loadStatus: string;
	postLoadStatus: string;
	deleteLoadStatus: string;
	
	status: string;
	cardBrand: string;
	last4: string;
}

const INITIAL_STATE: BillingState = {
	loadStatus: "",
	postLoadStatus: "",
	deleteLoadStatus: "",
	status: "",
	cardBrand: "",
	last4: "",
};

const reducer = createReducer(INITIAL_STATE, {
	"get-billing.start": (state) => ({ ...state, loadStatus: "pending" }),
	"get-billing": (state, action) => ({ ...state, ...action.data, loadStatus: "success" }),
	"set-billing.start": (state, action) => ({ ...state, ...action.data, postLoadStatus: "pending", }),
	"set-billing": (state, action) => ({ ...state, ...action.data, postLoadStatus: "success", }),
	"delete-billing.start": (state) => ({ ...state, deleteLoadStatus: "pending", }),
	"delete-billing": (state) => ({ ...state, status: "", cardBrand: "", last4: "", deleteLoadStatus: "success", }),
});

const selectState = (state: any): BillingState => state.billing as BillingState;
export const selectLoadStatus = (state: any) => selectState(state).loadStatus;
export const selectPostLoadStatus = (state: any) => selectState(state).postLoadStatus;
export const selectDeleteLoadStatus = (state: any) => selectState(state).deleteLoadStatus;
export const selectStatus = (state: any) => selectState(state).status;
export const selectCardBrand = (state: any) => selectState(state).cardBrand;
export const selectLastFour = (state: any) => selectState(state).last4;

export default reducer;